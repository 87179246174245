import React, { useState } from "react";
import { BsFillTelephoneFill } from 'react-icons/bs';
// import FadeUpDown from "../Animation/FadeUpDown";
import { IoIosMail } from 'react-icons/io'
// import axios from "axios";

const Form = () => {
  const [user, setUser] = useState({
    fname: "",
    lname: "",
    phone: "",
    email: "",
    message: "",
  });

  let name, value;
  const getUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    e.preventDefault();

    const { fname, lname, phone, email, message } = user;

    if (fname && lname && phone && email && message) {
      const res = await fetch(
        "https://uniweb-form-responses-default-rtdb.firebaseio.com//uniweb.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fname,
            lname,
            phone,
            email,
            message,
          }),
        }
      );

      if (res) {
        setUser({
          fname: "",
          lname: "",
          phone: "",
          email: "",
          message: "",
        });

        alert("Message Sent!");
      }
    } else {
      alert("Please fill all the fields!");
    }
  };

  return (
    <section
      style={{
        backgroundImage: `url('/assets/grp22.png)`,
        backgroundSize: "cover",
      }}
    >
      <div className="md:px-[10%] md:py-20 py-10 px-10" id="contact-us">
        <div className="grid lg:grid-cols-2 gap-20">
          <div className="grid place-content-between lg:px-7">
            <div className="h-full">
              <h1 className="md:text-5xl text-3xl leading-snug font-mon font-semibold">Leading ERP Company for <span className="text-primary">Business Solution</span></h1>
              <p className="py-5 text-base font-mon text-justify lg:pr-16">Discover the future of your business. Request a demo now and experience firsthand how our ERP solutions can transform your operations. Our experts are ready to guide you through the features and benefits, tailored to your needs. </p>
              <p className="pb-5 text-base font-mon text-justify lg:pr-16">Don't miss out on this chance to revolutionize the way you operate - request a demo today and embark on a journey toward a more efficient, productive, and successful future. </p>
              <div className="grid md:grid-cols-2">
                <div className="flex items-center gap-4">
                    <BsFillTelephoneFill className="h-5 w-5" />
                    <div className="text-grey font-medium">
                    <h3>+971 50 160 0729</h3>
                    <h3>+91 9821 142 731</h3>
                    <h3>+91 8898 582 056</h3>
                    <h3>+91 7500 445 053</h3>
                    </div>
                </div>
                <div className="flex items-center gap-4 md:pt-0 pt-5">
                    <div>

                    <IoIosMail className="w-7 h-7" />
                    </div>
                    <div className="text-grey font-medium">
                    <h3>sales@uniwebit.com</h3>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:mx-[10%] md:px-14 px-8 bg-white rounded-xl shadow-xl h-full border-2 border-gray">
          <h1 className="text-4xl text-center text-primary py-5 leading-snug font-mon font-semibold">Enquire Now</h1>

            <form method="POST" className="mb-8">
              {/* <FadeUpDown xOffset={15} delay={0.4}> */}
                <div class="grid md:grid-cols-2 md:gap-6">
                  <div class="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="fname"
                      id="fname"
                      class="block py-2.5 px-0 w-full text-sm bg-white/[0.1] border-0 border-b-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                      placeholder=" "
                      value={user.fname}
                      onChange={getUserData}
                      autoComplete="off"
                      required
                    />
                    <label
                      for="fname"
                      class="peer-focus:font-semibold font-semibold absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                    >
                      First Name
                    </label>
                  </div>
                  <div class="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="lname"
                      id="lname"
                      class="block py-2.5 px-0 w-full text-sm  bg-white/[0.1] border-0 border-b-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                      placeholder=" "
                      value={user.lname}
                      onChange={getUserData}
                      autoComplete="off"
                      required
                    />
                    <label
                      for="lname"
                      class="peer-focus:font-semibold font-semibold absolute text-sm  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                    >
                      Last Name
                    </label>
                  </div>
                </div>
                <div class="grid md:grid-cols-2 md:gap-6">
                  <div class="relative z-0 mb-6 w-full group">
                    <input
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      name="phone"
                      id="phone"
                      class="block py-2.5 px-0 w-full text-sm  bg-white/[0.1] border-0 border-b-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                      placeholder=" "
                      value={user.phone}
                      onChange={getUserData}
                      autoComplete="off"
                      required
                    />
                    <label
                      for="phone"
                      class="peer-focus:font-semibold font-semibold absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                    >
                      Phone Number
                    </label>
                  </div>
                  <div class="relative z-0 mb-6 w-full group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      class="block py-2.5 px-0 w-full text-sm bg-white/[0.1] border-0 border-b-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                      placeholder=" "
                      value={user.email}
                      onChange={getUserData}
                      autoComplete="off"
                      required
                    />
                    <label
                      for="email"
                      class="peer-focus:font-semibold font-semibold absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                    >
                      Your Email
                    </label>
                  </div>
                </div>
                <div class="relative z-0 mb-6 w-full group">
                  <textarea
                    rows={3}
                    type="text"
                    name="message"
                    id="message"
                    class="block py-2.5 px-0 w-full text-sm bg-white/[0.1] border-0 border-b-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                    placeholder=" "
                    value={user.message}
                    onChange={getUserData}
                    autoComplete="off"
                    required
                  />
                  <label
                    for="message"
                    class="peer-focus:font-semibold font-semibold absolute text-sm  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                  >
                    Message
                  </label>
                </div>
                <div className="pt-5 grid">
                  <button
                    onClick={postData}
                    type="submit"
                    className="text-base font-mono text-white shadow-xl bg-secondary px-7 py-2 rounded-lg font-medium border-2 border-secondary hover:text-secondary hover:bg-white"
                  >
                    Send Message
                  </button>
                </div>
              {/* </FadeUpDown> */}
            </form>
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default Form;
