import React from "react";
import { GoDotFill } from "react-icons/go";
import FadeUpDown from "../Animation/FadeUpDown";

const Offline = () => {
  return (
    <div>
      <div
        className="lg:px-[15%] px-[10%] pb-16"
        style={{
          backgroundImage: `url('Assets/Group2.png')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
        }}
      >
        <div className="flex flex-col justify-center items-center py-16">
          <FadeUpDown yOffset={15} delay={0.2}>
          <h1 className="font-semibold  lg:text-[3.5vw] text-2xl md:text-4xl">
            Comprehensive <span className="text-primary">Services</span>
          </h1>
          </FadeUpDown>
          <FadeUpDown yOffset={15} delay={0.4}>
          <p className="text-center md:text-xl text-sm font-medium lg:w-[90%] pt-6">
            We offer more than just software - we provide a complete suite of
            services to propel your business forward. Choose from our range of
            ERP solutions tailored to your infrastructure needs: streamline
            operations with our offline ERP software for local systems, achieve
            centralized control with our server-based option, or enjoy
            flexibility and scalability through our cloud-based platform.
          </p>
          </FadeUpDown>
        </div>
        <div className="rounded-3xl pb-8 bg-white shadow-3xl">
          <div className="grid lg:grid-cols-12">
            <div className="col-span-5 px-4 sm:pl-8 pb-10 order-2 lg:order-1 lg:py-[20%] xl:py-[40%]">
                <FadeUpDown>
              <div className="xs:pl-4">
                <div className="w-8 flex justify-center items-center h-8 font-semibold bg-primary text-white">
                  1
                </div>
                <h1 className="lg:text-[2.5vw] text-[6vw] sm:text-[3vw] font-semibold">
                  Offline ERP Software for{" "}
                  <span className="text-primary">Local System</span>
                </h1>
              </div>
                </FadeUpDown>
                <FadeUpDown xOffset={-15} delay={0.8}>
              <div className="flex gap-4 pt-6 flex-col">
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Enhanced data privacy compliance</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Faster performance and response times</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Offline access for uninterrupted operations</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Cost control with one-time licensing/purchase</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Independence from internet connectivity</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Complete data ownership and control</p>
                </div>
              </div>
                </FadeUpDown>
            </div>
              <FadeUpDown xOffset={15} delay={0.2}>
            <div className="col-span-7 lg:order-2 order-1  items-center flex justify-end ">
              <img
                className="h-[70%] rounded-l-3xl"
                src="/Assets/Group9.png"
                alt=""
                />
            </div>
                </FadeUpDown>
          </div>
          <div className="grid lg:grid-cols-12">
              <FadeUpDown xOffset={-15} delay={0.4}>
            <div className="col-span-6  items-center flex  justify-end lg:justify-start">
              <img
                className="h-[70%] rounded-l-3xl"
                src="/Assets/Group10.png"
                alt=""
                />
            </div>
                </FadeUpDown>
            <div className="col-span-6 px-4 sm:pl-8 lg:py-[40%]">
              <FadeUpDown yOffset={15} delay={0.4}>
              <div className="xs:pl-4">
                <div className="w-8 flex justify-center items-center h-8 font-semibold bg-primary text-white">
                  2
                </div>
                <h1 className="lg:text-[2.5vw] text-[6vw] sm:text-[3vw] font-semibold text-primary ">
                  Server Based
                </h1>{" "}
                <span className="text-black g:text-[2.5vw] text-[6vw] sm:text-[3vw] font-semibold">
                  ERP Software
                </span>
              </div>
              </FadeUpDown>
              <FadeUpDown xOffset={-15} delay={0.4}>
              <div className="flex gap-4 pt-6 flex-col">
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Centralized data management</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Enhanced data security</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>High performance and response times</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>High performance and response times</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Local access foruninterrupted operations</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Integration capabilities with existing systems</p>
                </div>
              </div>
              </FadeUpDown>
            </div>
          </div>
          <div className="grid lg:grid-cols-12">
            <div className="col-span-5 order-2 lg:order-1 px-4 sm:pl-8 xl:py-[40%]">
              <FadeUpDown yOffset={15} delay={0.2}>
              <div className="xs:pl-4">
                <div className="w-8 flex justify-center items-center h-8 font-semibold bg-primary text-white">
                  3
                </div>
                <h1 className="lg:text-[2.5vw] text-[6vw] sm:text-[3vw] font-semibold  text-primary ">
                  Cloud Based
                </h1>{" "}
                <span className="text-black lg:text-[2.5vw] text-[6vw] sm:text-[3vw] font-semibold ">
                  ERP Software
                </span>
              </div>
              </FadeUpDown>
              <FadeUpDown xOffset={-15} delay={0.4}>
              <div className="flex gap-4 pt-6 flex-col">
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Convenient accessibility from anywhere with internet</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Automated mail & SMS option</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Client to client web login for order process</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Collaboration and mobility for seamless teamwork</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Reliable data backup and disaster recovery solutions</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Seamless integration with other business applications</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Real-time analytics for data-driven decision-making</p>
                </div>
                <div className="flex gap-2 items-center">
                  <GoDotFill size={15} />
                  <p>Mobile ERP Soluions</p>
                </div>
              </div>
              </FadeUpDown>
            </div>
            <div className="col-span-7 order-1 lg:order-2 items-center flex justify-end ">
              <img
                className="h-[70%] rounded-l-3xl"
                src="/Assets/Group8.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offline;
