import React from 'react'
import FadeUpDown from '../Animation/FadeUpDown'

const Pilars = () => {
    const List=[
        {

            name:'Mr C. Prakash',
            title:'Director',
            img: '/Assets/CP.png'
        },
        {

            name:'Mr Jaideep Soni',
            title:'Director',
            img: '/Assets/Jaideep.png'
        },
        {

            name:'Mr Sandeep Upadhyay',
            title:'Director',
            img: '/Assets/Sandeep.png'
        },
        {

            name:'Mr Abdul Wafa',
            title:'Director',
            img: '/Assets/Abdul.png'
        },
        {

            name:'Mr B.D. Khullar',
            title:'Director',
            img: '/Assets/braham.png'
        },
       
    ]
  return (
    <div>
    <div className='text-white pt-[12%] pb-[8%] lg:px-[7%] xl:px-[12%] xs:px-[6%] sm:px-[15%] md:px-[22%] px-[17%]' style={{backgroundImage:`url('Assets/Group20.png')`,
    backgroundSize:'cover'}}>
        <FadeUpDown yOffset={15} delay={0.2}>
    <h1 className='text-center text-2xl xs:text-4xl font-semibold'>Our Qualified Pilars</h1>
        </FadeUpDown>
        <FadeUpDown yOffset={15} delay={0.4}>
        <div className='grid gap-10 xs:gap-6 pt-[13%] lg:grid-cols-5'>
        {
            List.map((Lists)=>(
                
                <div className="bg-primary rounded-xl px-6 pt-6">
            <h1 className=''>{Lists.name}</h1>
            <p className='pb-8 text-gray'>{Lists.title}</p>
            <div className=''>
                <img className='h-[35vh] mx-auto pb-5 object-cover' src={Lists.img} alt={Lists.title} />
            </div>
        </div>
            ))
            
        }

        </div>
        </FadeUpDown>
    </div>

      
    </div>
  )
}

export default Pilars