import React from 'react'
import FadeUpDown from '../Animation/FadeUpDown'

const Dynamic = () => {
  return (
    <div>
    <div style={{backgroundImage:`url('Assets/Group14.png')`,
    backgroundSize:'cover',
    backgroundPosition:'center'}}>
    <div className='grid px-[8%] lg:pl-[15%] pb-[5%]  lg:grid-cols-2'>
    <div className='flex justify-center'>

    
    <div className=' pt-[12%]'>
      <FadeUpDown xOffset={-20} delay={0.2}>
        <h1 className='sm:text-5xl text-2xl pb-10 font font-semibold leading-loose'>We’re <span className='text-primary'>dynamic team</span> of creative people with innovative mind</h1>
        <p className='pb-6 sm:text-lg'>To thrive in the competitive environment, businesses must not only develop high-quality products but also navigate the complexities of growth. This is where Uniweb IT Solutions steps in. We offer tailor-made IT solutions that empower companies to excel at what they do best while enhancing overall efficiency. Our legacy at Uniweb IT Solutions is rooted in innovation, growth, and technological advancement.
        </p>
        <p className='sm:text-lg'>With a track record of innovation, expansion, and growth, Uniweb IT Solutions is poised to lead businesses toward success in this ever-evolving era. </p>
      </FadeUpDown>
    </div>
    </div>
    <FadeUpDown xOffset={20} delay={0.4}>
    <div className='flex justify-center items-center mt-5 lg:pr-[10%]'><img className='w-[80%] pt-12  h-fit' src="/Assets/Group15.png" alt="" /></div>
    </FadeUpDown>
        
    </div>

    </div>
      
    </div>
  )
}

export default Dynamic