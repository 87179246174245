import React from 'react'
import { Dynamic, Erp3, Erpa, Mission, Pilars } from '../Components'

const AboutUs = () => {
  return (
    <div>
        <Erpa />
        <Dynamic />
        {/* <Important /> */}
        <Erp3 />
        <Mission />
        <Pilars />
    </div>
  )
}

export default AboutUs