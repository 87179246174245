import React from "react";

const Modules = () => {
  const List=[
    {
    name:'Inventory Management',
    img:'demo.png',
    text: 'Effective features to minimize warehousing costs and optimize storage needs in line with the requirements at hand.'
  },
    {
    name:'Inventory Management',
    img:'demo2.png',
    text: 'Streamlines order processing, tracking, and fulfillment while enhancing operational efficiency.'
  },
    {
    name:'Inventory Management',
    img:'demo3.png',
    text: 'Facilitates streamlined procurement processes, including supplier management, invoice processing, and inventory control, optimizing procurement efficiency and cost savings.'
  },
    {
    name:'Inventory Management',
    img:'demo4.png',
    text: 'Efficient planning, scheduling, and control of manufacturing processes, including resource allocation, material equirements planning (MRP), and ensuring timely delivery of products.'
  },
    {
    name:'Inventory Management',
    img:'demo5.png',
    text: 'Enables seamless sales transactions, including inventory lookup, pricing, discounts, payment processing, and receipt generation, while providing real-time data integration for accurate sales reporting and analysis.'
  },
    {
    name:'Inventory Management',
    img:'demo6.png',
    text: 'Streamlines sales processes, from lead management to invoicing, while providing real-time data insights for effective sales tracking and customer relationship management.'
  },
    {
    name:'Inventory Management',
    img:'demo7.png',
    text: 'The finance module in an ERP system handles financial transactions, budgeting, and reporting, enabling effective financial management and informed decision-making.'
  },
    {
    name:'Inventory Management',
    img:'demo8.png',
    text: 'Provides tools for defining quality standards, conducting inspections, managing non-conformances, tracking corrective actions, and generating quality reports.'
  },
    
   
    
  ]
  return (
    <div  className="pt-[5%] max-w-[1647px] mx-auto pb-[5%]" style={{backgroundImage:`url('Assets/Group22.png')`,
    backgroundSize:'cover',
    backgroundPosition:'center'}}>
      <div className="xl:px-[10%] px-[17%] xs:px-[10%] sm:px-[15%] md:px-[20%] lg:px-[3%] text-gray-300 h-full p-4 flex justify-center mx-auto flex-col">
        <div className="">
        <h1 className="lg:text-5xl text-2xl xs:text-3xl pb-[15%] lg:pb-[5%] text-center font-semibold">Uniweb ERP <span className="text-primary">Modules</span></h1>
          
        </div>
        <div className="grid lg:grid-cols-12 gap-6">
        <div className="grid lg:col-span-8 md:grid-cols-2 xs:grid-cols-2 lg:grid-cols-4 gap-6">
        {
          List.map((Lists)=>(
            <div className="shadow-lg rounded-2xl shadow-black">
            <div style={{backgroundImage:`url('/Assets/${Lists.img}')`,
    backgroundSize:'cover',
    backgroundPosition:'center'}}
              
              className="sm:h-[270px] lg:h-[220px] md:h-[330px] h-[320px] xs:h-[300px]  rounded-lg bg-cover bg-repeat bg-center"
            >
              <div className="flex opacity-0 flex-col rounded-md  hover:opacity-100 bg-gradient-to-r hover:from-[#5651e5] hover:to-[#709dff] justify-center  items-center h-full gap-4 ">
                
                <p className="px-4 text-white text-sm">{Lists.text}</p>
                
              </div>
            </div>
          </div>
          ))
        }
         
          
        </div>
        <div className="lg:col-span-4">
        <div className="shadow-lg rounded-2xl shadow-black">
            <div style={{backgroundImage:`url('/Assets/demo9.png')`,
    backgroundSize:'cover',
    backgroundPosition:'center'}}
              
              className="sm:h-[463px] h-[320px] xs:h-[600px]  rounded-lg bg-cover bg-repeat bg-center"
            >
              <div className="flex opacity-0 flex-col rounded-md  hover:opacity-100 bg-gradient-to-r hover:from-[#5651e5] hover:to-[#709dff] justify-center  items-center h-full gap-4 ">
                
                <p className="px-4 text-white">Generate reports to find pre sales and post sales service time.</p>
                
              </div>
            </div>
          </div>

        </div>

        </div>
      </div>
    </div>
  );
};

export default Modules;