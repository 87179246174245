import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./Layout/Navbar";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import HomePage from "./Pages/HomePage";
import Products from "./Pages/Products";
import Footer from "./Layout/Footer";

function App() {
  return (
    <section>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/products" element={ <Products /> } />
          <Route path="/contact" element={ <ContactUs /> } />
        </Routes>
        <Footer />
      </BrowserRouter>
      
    </section>
  );
}

export default App;
